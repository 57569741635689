body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 28px;
  font-weight: 300;
}
div {
  box-sizing: border-box;
}
h1, .h1 {
  font-size: 28px;
  font-weight: 300;
  line-height: 38px;
}

h2, .h2 {
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0.2px;
  line-height: 38px;
}

h3, .h3 {
}

h4, .h4 {

}

ul {
  padding: 0;
  list-style: none;
}

img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

input {
  border-radius: 0;
  height: 30px;
  font-size: 14px;
}

textarea {
  font-size: 14px;
}

select {
  visibility: hidden;
}

*:focus {
  outline: none;
}